<template>
  <v-container id="dashboard" class="pb-0" fluid tag="section">
    <v-row dense>
      <v-col class="d-flex" cols="12">
        <v-breadcrumbs class="py-2" :items="breadcrumbs">
          <template v-slot:item="{ item }">
            <router-link :to="item.href" class="v-breadcrumbs__item">
              {{ item.text }}
            </router-link>
          </template>
        </v-breadcrumbs>
        <v-spacer />
        <v-btn-toggle v-model="selectedView" class="mb-1" dense mandatory>
          <v-btn icon>
            <v-icon>mdi-view-compact</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon>mdi-view-module</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon>mdi-view-list</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
    </v-row>
    <virtual-scroll
      ref="virtualCollection"
      :records="records"
      collection="marc_mon"
      :selected-view="selectedView"
      @reload="getRecords"
    />
    <!-- <v-row v-if="selectedView == 0 || selectedView == 2">
      <v-col
        v-for="itm in records"
        :key="itm._id"
        cols="12"
        :sm="selectedView == 0 ? 6 : 12"
        :md="selectedView == 0 ? 6 : 12"
        :lg="selectedView == 0 ? 4 : 12"
        :xl="selectedView == 0 ? 3 : 12"
      >
        <view-mon :record="itm" :baseURL="baseURL" :category="category" />
      </v-col>
    </v-row>
    <div v-if="selectedView == 1">
      <v-row>
        <v-col v-for="itm in records" :key="itm._id" cols="6" sm="4" md="3" lg="2">
          <v-card class="d-flex mr-0 mt-0 mb-0" height="100%">
            <v-card-text>
              <v-img
                class="d-float-left"
                :src="baseURL + 'coperta/?pre=mon&rid=' + itm._id"
                lazy-src="/image/default/mon/default.png"
                contain
                width="100%"
                height="100%"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div> -->
  </v-container>
</template>
<script>
import axios from "../../../plugins/axios"
import VirtualScroll from "../components/containers/VirtualScroll"
// import ViewMon from "../components/containers/ViewMon"
export default {
  name: "DashboardDashboard",
  components: { VirtualScroll },
  data() {
    return {
      totalResults: 1000000,
      itemsPerPage: 25,
      selectedView: 0,
      page: 1,
      bottom: false,
      category: "",
      records: []
    }
  },
  computed: {
    companyId() {
      return this.$store.getters.companyId
    },
    breadcrumbs() {
      const arr = [
        {
          text: "Acasă",
          disabled: false,
          href: "/"
        }
      ]
      if (this.category != "") {
        arr.push({
          text: "Monografii",
          disabled: false,
          link: true,
          href: "/mon"
        })
      }
      return arr
    }
  },
  watch: {
    $route() {
      this.category = ""
      //this.$log("resetCollectionr")
      if (this.$route.path.indexOf("cat-") > 0) {
        this.category = this.$route.path.substring(this.$route.path.indexOf("cat-") + 4, this.$route.path.length)
      }
      if (this.$route.name == "CategoriaMon" || this.$route.name == "Monografii") {
        if (this.$refs.virtualCollection) {
          this.$refs.virtualCollection.resetCollection()
        }
        this.page = 1
        this.records = []
        this.getRecords()
        this.getRecords()
      }
    }
  },
  created() {
    //this.$log("rout cr ", this.$route)
    if (this.$route.path.indexOf("cat-") > 0) {
      this.category = this.$route.path.substring(this.$route.path.indexOf("cat-") + 4, this.$route.path.length)
    }
    //this.$log("cat ", this.category)
    this.getRecords()
    this.getRecords()
  },
  methods: {
    openDetails(itm) {
      this.$log("openDetails ", itm)
      this.$router.push("/mon/" + itm._id)
    },
    getRecords() {
      //this.$log("getRecords mon ")
      if (this.itemsPerPage * (this.page - 1) <= this.totalResults) {
        let cat = ""
        if (this.category != "") {
          cat = '&where={"ctg":"' + this.category + '"}'
        }
        axios.get("ebib_marc_mon?max_results=" + this.itemsPerPage + "&page=" + this.page + cat).then(response => {
          this.totalResults = response.data._meta.total
          //this.records = []
          response.data._items.map(itm => {
            const rec = {}
            rec._id = itm._id
            for (const itms in itm) {
              if (itms != "fields") {
                rec[itms] = itm[itms]
              }
            }
            if (itm.fields["299"]) {
              rec.title = itm.fields["299"][0].a[0].val
            } else if (itm.fields["200"]) {
              rec.title = itm.fields["200"][0].a[0].val
              if (itm.fields["200"][0].c) {
                rec.title += ". " + itm.fields["200"][0].c[0].val
              }
              if (itm.fields["200"][0].d) {
                rec.title += " = " + itm.fields["200"][0].d[0].val
              }
              if (itm.fields["200"][0].h) {
                rec.title += " " + itm.fields["200"][0].h[0].val
              }
              if (itm.fields["200"][0].i) {
                rec.title += " : " + itm.fields["200"][0].i[0].val
              }
            } else {
              rec.title = ""
            }
            for (const flds in itm.fields) {
              for (const fld in itm.fields[flds]) {
                for (const sub in itm.fields[flds][fld]) {
                  if (sub.length == 1) {
                    for (const subs in itm.fields[flds][fld][sub]) {
                      if (rec["fields." + flds + "." + sub + ".val"]) {
                        rec["fields." + flds + "." + sub + ".val"] += ", " + itm.fields[flds][fld][sub][subs].val
                      } else {
                        rec["fields." + flds + "." + sub + ".val"] = itm.fields[flds][fld][sub][subs].val
                      }
                    }
                  } else {
                    rec["fields." + flds + "." + sub] = itm.fields[flds][fld][sub]
                  }
                }
              }
            }
            //this.records.push(rec)
            this.records.push({
              data: rec
            })
          })
          // this.$nextTick(() => {
          //   if (this.bottomVisible()) {
          //     this.getRecords()
          //   }
          // })
        })
        this.page++
      }
    }
  }
}
</script>
<style>
/* .mondash {
  height: calc(100vh - 75px);
} */
</style>
